<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.keyword"
        label="搜索表单"
        v-bind="layout"
        placeholder="请输入表单名称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.mode"
        v-bind="layout"
        key-field="id"
        label="表单模式"
        inner-search
        placeholder="全部模式"
        value-field="id"
        label-field="name"
        show-all
        :options="modeData"
      />
      <BjSelect
        v-model="search.collect_status"
        v-bind="layout"
        key-field="id"
        label="收集状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="collectData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">
        表单列表
        <dustbin :class="$style.dustbin" :count="recycleTotal" @refresh="refresh" />
      </p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #action="item">
          <bj-link type="primary" @click="onDetail(item)"> 详情 </bj-link>
          <bj-link type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
          <a-popconfirm
            title="确定要删除表单吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
            @confirm="onDelete(item)"
          >
            <bj-link class="ml-4" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { appFormServce } from '@/service'

import dustbin from './dustbin.vue'

const service = new appFormServce()

export default {
  name: 'Home',
  components: {
    dustbin,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      data: [],
      search: {
        keyword: null,
        collect_status: 0,
        status: 0,
        mode: 0,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      modeData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '登记模式',
          id: 1,
        },
        {
          name: '预订模式',
          id: 2,
        },
      ],
      statusData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '已发布',
          id: 1,
        },
        {
          name: '未发布',
          id: 2,
        },
      ],
      collectData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '收集中',
          id: 1,
        },
        {
          name: '已结束',
          id: 2,
        },
      ],
      recycleTotal: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '表单名称',
          dataIndex: 'name',
          width: 200,
          ellipsis: true,
        },
        {
          title: '关联信息',
          dataIndex: 'relation_name',
          width: 200,
          ellipsis: true,
          customRender: value => <span class='primary'>{value}</span>,
        },
        {
          title: '回收方式',
          dataIndex: 'recycle_type',
          customRender: value => {
            switch (value) {
              case 0:
                return '满额回收'
              case 1:
                return '定时回收'
              case 2:
                return '定时满额'
            }
          },
        },
        {
          title: '表单模式',
          dataIndex: 'mode',
          customRender: value => {
            switch (value) {
              case 1:
                return '登记模式'
              case 2:
                return '预订模式'
            }
          },
        },
        {
          title: '收集状态',
          dataIndex: 'collect_status',
          customRender: value => {
            switch (value) {
              case 1:
                return <bj-tag type='primary'>收集中</bj-tag>
              case 2:
                return <bj-tag type='default'>已结束</bj-tag>
            }
          },
        },
        {
          title: '发布状态',
          dataIndex: 'status',
          customRender: value => {
            switch (value) {
              case 1:
                return <bj-tag type='primary'>已发布</bj-tag>
              case 2:
                return <bj-tag type='default'>未发布</bj-tag>
            }
          },
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '操作',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.refresh()
  },
  methods: {
    async getTotal() {
      const { data } = await service.recycleTotal()
      this.recycleTotal = data
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        keyword: null,
        group_id: '',
        status: 0,
        activity_status: 0,
      }
      this.onSearch()
    },
    async getList() {
      const { data } = await service.getList({
        page: this.page,
        page_size: this.pageSize,
        ...this.search,
      })
      this.data = data.record
      this.total = data.total
    },
    onEdit(item) {
      this.$router.push({
        name: 'appFormAdd',
        query: {
          id: item.id,
        },
      })
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'app_form',
          id: item.id,
        },
      })
    },
    onDetail(item) {
      this.$router.push({
        name: 'appFormDetail',
        query: {
          id: item.id,
        },
      })
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onDelete(item) {
      await service.delete({
        id: item.id,
      })
      this.refresh()
      this.$message.success('删除成功')
    },
    refresh() {
      this.getList()
      this.getTotal()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    min-height: calc(100vh - 252px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      position: relative;
      color: #000;
      font-size: 16px;
    }

    .dustbin {
      position: absolute;
      right: 40px;
    }

    .cover {
      display: flex;
      align-items: center;
      max-width: 250px;
      color: #000;
      font-size: 14px;

      .img {
        width: 64px;
        height: 64px;
        margin-right: 10px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
